<template>
  <div class="admin-settings-edit-playbook">
    <loading-screen :is-loading="isSaving"></loading-screen>

    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">Edit Playbook</h1>
      <div class="actions">
        <a-button
          class="button-margin-left btn-rounded"
          icon="arrow-left"
          size="large"
          type="primary"
          @click.prevent="goBack"
          >Back</a-button
        >
      </div>
    </div>
    <!-- / Page Header -->

    <!-- Is Loading -->
    <div v-if="isLoading" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <!-- Form -->
    <div v-if="!isLoading" class="form">
      <a-form-item label="Playbook Name">
        <a-input size="large" v-model="playbook.displayName"></a-input>
      </a-form-item>

      <a-form-item label="Description">
        <a-input size="large" v-model="playbook.description"></a-input>
      </a-form-item>

      <a-form-item label="Visibility">
        <a-radio-group v-model="playbook.scope">
          <a-radio :value="1">Shown to organisation</a-radio>
          <!-- <a-radio :value="2">Shown to whole tenant</a-radio> -->
          <a-radio :value="3">Hidden from lists</a-radio>
        </a-radio-group>
      </a-form-item>
    </div>
    <!-- / Form -->

    <a-button
      v-if="!isLoading"
      @click.prevent="save"
      class="btn-rounded save-button"
      size="large"
      type="primary"
      >Save</a-button
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingScreen from "../../../../components/LoadingScreen.vue";
import checklists from "../../../../api/checklists";
export default {
  data() {
    return {
      isSaving: false,
      isLoading: false,
      playbook: null,
    };
  },
  components: { LoadingScreen },
  created() {
    this.loadPlaybook();
  },
  computed: {
    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
    }),

    playbookId() {
      return this.$route.params.id;
    },
  },
  methods: {
    goBack() {
      this.$router.push("/admin/settings/playbooks/" + this.playbookId);
    },

    getValidationErrors() {
      let errors = [];
      if (this.playbook.displayName.trim().length == 0) {
        errors.push("Please provide a playbook name");
      }
      if (this.playbook.description.trim().length == 0) {
        errors.push("Please provide a description");
      }
      return errors;
    },

    loadPlaybook() {
      let vm = this;
      vm.isLoading = true;
      checklists
        .getChecklist(this.tenantId, this.playbookId)
        .then((r) => {
          vm.isLoading = false;
          vm.playbook = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          vm.$message.error("Error editing playbook");
          vm.$router.push("/admin/settings/playbooks/" + this.playbookId);
        });
    },

    async save() {
      let vm = this;

      // Validation
      let validationErrors = this.getValidationErrors();
      if (validationErrors.length) {
        this.$message.error(validationErrors[0]);
        return false;
      }

      vm.isSaving = true;

      try {
        await checklists.updateChecklist(vm.tenantId, vm.playbook)
        vm.isSaving = false;
        vm.$message.success('Playbook updated successfully');
        vm.$router.push('/admin/settings/playbooks/' + vm.playbookId);
      } catch (e) {
        console.log(e);
        vm.isSaving = false;
        vm.$message.error('Error saving playbook');
      }
    },
  },
};
</script>

<style lang="scss">
.admin-settings-edit-playbook {
  padding-bottom: 40px;

  .loader {
    // background: #fff;
    padding: 50px;
    text-align: center;
  }

  .form {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
  }

  .button-margin-left {
    margin-left: 10px;
  }

  .save-button {
    margin-top: 20px;
  }
}
</style>